
import React from 'react'

function Cardcrousel() {
  return (
    <>
      <div>
      <iframe width="100%" height="500" src="https://www.youtube.com/embed/bHPJxHKVkSM" title="⛽️Crude Oil Manufacturing Production Process  - Extraction and Refining Crude Oil - Petrol Factory" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      </>
  )
}

export default Cardcrousel
