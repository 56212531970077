import React from 'react';
import './Whoweare.css';
import { ForwardToInboxSharp } from '@mui/icons-material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import about from '../../../image/about.png'

const Whoweare = () => {
    
  return (
    < >
    <div>
 
    <div className="whoweare">
        <div className='content'>
        
      <h1>Who We Are ?</h1>
     <p>
     since 2020, supplier of petrochemical oil products and chemical, coal and solvent products since we doing this buisness for 3 years.
so, we have alot of happy industriel coustomers in south of india.
we are giving the products for the most of clients around Tamilnadu, Kerala, Karnataka, Telangana, and Andhrapradesh.
so, we that carrying our trust with most of the costumers.
     </p>
     <p>
     At <mark>Vajaravel traders pvt. ltd.</mark>, we are committed to delivering top-notch products and exceptional service to our clients. With our extensive experience and industry expertise, we strive to exceed expectations and contribute to the success of businesses across South India.
     </p>
     
    </div>

      
    </div>
    </div>
    </>
  );
};

export default Whoweare;

